import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/index.scss";
import i18n from "@/i18n";

import Keycloak, { KeycloakConfig, KeycloakOnLoad } from "keycloak-js";
import axiosClient from "@/axios";
import { AxiosRequestConfig } from "axios";

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: "login-required",
};

const keycloak = Keycloak(initOptions as KeycloakConfig);

let tokenInterceptor: number;

keycloak
  .init({ onLoad: initOptions.onLoad as KeycloakOnLoad })
  .then((auth: boolean) => {
    if (!auth) {
      window.location.reload();
    } else {
      if (keycloak.token) {
        tokenInterceptor = createTokenInterceptor(keycloak.token);
      }

      createApp(App)
        .use(store)
        .use(i18n)
        .use(router)
        .provide("keycloak", keycloak)
        .mount("#app");
    }

    setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
        if (!refreshed) {
          return;
        }

        if (tokenInterceptor) {
          axiosClient.interceptors.request.eject(tokenInterceptor);
        }

        if (keycloak.token) {
          tokenInterceptor = createTokenInterceptor(keycloak.token);
        }
      });
    }, 6000);
  });

const createTokenInterceptor = (token: string): number => {
  return axiosClient.interceptors.request.use(function (
    config: AxiosRequestConfig
  ) {
    // console.log(config);
    if (config.baseURL?.includes("customer_api")) {
      return config;
    }
    config.headers = {
      ...config.headers,
      ...{
        "Authorization-Token": `${token}`,
      },
    };

    return config;
  });
};
