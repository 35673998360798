import instance, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { EventBus } from "@/common/Tools";

const axiosClient = instance.create();

axiosClient.defaults.baseURL = process.env.VUE_APP_API_URL;

axiosClient.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    ...{
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
  };

  return config;
});

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response?.status === 420) {
      const responseData = error.response.data;
      EventBus.emit(
        "webshipperError",
        `${responseData.sentry_code}_${responseData.user_id}`
      );
    }
    return Promise.reject(error);
  }
);

export const updateCustomerIdHeader = (customerId: number): void => {
  delete axiosClient.defaults.headers.common["X-Customer-Id"];
  if (customerId) {
    axiosClient.defaults.headers.common["X-Customer-Id"] =
      customerId.toString();
  }
};

export default axiosClient;
