import _ from "lodash";
import { useI18n } from "vue-i18n";

export type UnknownFunction = (data: unknown) => Promise<void> | void;

export const EventBus = {
  listeners: {} as Record<string, UnknownFunction[]>,
  on: (eventName: string, action: UnknownFunction): void => {
    _.set(EventBus.listeners, eventName, [
      ..._.get(EventBus.listeners, eventName, []),
      action,
    ]);
  },
  emit: (eventName: string, data?: unknown): void => {
    _.reduce(
      _.get(EventBus.listeners, eventName, []),
      async (process, action: UnknownFunction) => {
        await process;
        process = new Promise((resolve) => {
          resolve(action(data));
        });
        return process;
      },
      Promise.resolve()
    );
  },
};

export interface Errors {
  [key: string]: string[] | Errors;
}

export const prepareErrors = (
  errors: Errors,
  prefix = "",
  arrayPrefix = ""
): string => {
  const { t } = useI18n();
  return _.transform(
    errors,
    (result: string[], details, field) => {
      if (_.isArray(details)) {
        result.push(
          _.reduce(
            details,
            (innerResult, message) => {
              innerResult += ` ${arrayPrefix}${t(
                `parcels.validation_fields.${prefix}${field}`
              )} ${message.toLowerCase()};`;
              return innerResult;
            },
            ""
          )
        );
      }
      if (_.isPlainObject(details)) {
        if (!_.isNaN(Number(field))) {
          result.push(
            prepareErrors(
              details as Errors,
              "",
              `${t(
                `parcels.validation_fields.${prefix.split(".")[0]}_array_item`
              )} ${Number(field) + 1} `
            )
          );
        } else {
          result.push(prepareErrors(details as Errors, `${field}.`));
        }
      }
    },
    [] as string[]
  ).join("<br>");
};
