import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homepage",
    redirect: "/parcels",
    component: () => import("@/views/Dashboard.vue"),
    children: [
      {
        path: "/parcels",
        component: RouterView,
        children: [
          {
            path: "",
            name: "parcels.list",
            component: () => import("@/views/parcels/ParcelsList.vue"),
          },
        ],
      },
      {
        path: "/return-parcels",
        component: RouterView,
        children: [
          {
            path: "",
            name: "return_parcels.list",
            component: () => import("@/views/returns/ReturnParcelsList.vue"),
          },
        ],
      },
      {
        path: "/bulk-parcels",
        component: RouterView,
        children: [
          {
            path: "",
            name: "bulk_parcels.list",
            component: () => import("@/views/bulk-parcels/BulkParcelsList.vue"),
          },
          {
            path: ":id/errors",
            name: "bulk_parcels.errors",
            component: () =>
              import("@/views/bulk-parcels/BulkParcelsErrors.vue"),
          },
        ],
      },
      {
        path: "/reports",
        component: RouterView,
        children: [
          {
            path: "",
            name: "reports.list",
            component: () => import("@/views/reports/ReportsList.vue"),
          },
        ],
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
      },
      {
        path: "/download/:url(.*)",
        name: "download",
        component: () => import("@/views/DownloadFile.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
