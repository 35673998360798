import { createI18n } from "vue-i18n";

const messages = {
  en: {
    pagination: {
      count: {
        showing: "Showing",
        of_rows: "of {number} rows",
      },
      per_page: {
        show: "Show",
        row_per_page: "row per page",
      },
    },
    filters: {
      from_date: "From DD/MM/YYYY",
      to_date: "To DD/MM/YYYY",
    },
    account: {
      general_error:
        "There was a problem loading your account details. Please contact admin.",
      account: {
        title: "My Account",
        name: "Name",
        email: "E-mail address",
      },
      secrets: {
        title: "Secrets",
        modal: "Your secrets:",
        button: "Show Secrets",
        copy_button: "Copy Secret",
        confirm: "Close",
      },
      settings: {
        title: "Account Settings",
        password: "Password",
        change_password: "Change password",
        new_password: {
          input: "Enter new password:",
          repeat_input: "Re-enter new password:",
          repeat_error: "Passwords do not match!",
          length_error: "Passwords must be between 4 and 70 characters!",
          general_error:
            "There are problems with changing password, please try again later.",
          general_success: "Password changed successfuly!",
        },
      },
      company_details: {
        title: "Company details",
        name: "Company Name",
        company: "Company",
        contact_email: "Contact Email",
        contact_name: "Contact Name",
        client_id: "Client ID",
        registration_number: "Registration number",
        tax_id: "Tax ID",
        url: "URL",
      },
      address: {
        title: "Address",
        first_address_line: "Address Line 1",
        second_address_line: "Address Line 2",
        third_address_line: "Address Line 3",
        city: "City",
        postal_code: "Postal code",
        state: "State",
        country: "Country",
        phone: "Phone",
      },
      subcustomers: {
        orders: "orders",
        title: "Company's subcustomers",
        name: "Name",
        client_id: "Client ID",
        city: "City",
        country: "Country",
        parcels: "parcels",
        registration_number: "Registration number",
        tax_id: "Tax ID",
      },
    },
    validation: {
      invalid_email: "Email is invalid",
      invalid_phone: "Invalid phone number",
      invalid_zip_code: "Invalid zip code",
      url: "Web address is invalid",
      missing_information: "Missing or wrong information",
      missing_username: "Missing username address",
      missing_password: "Missing password",
      number: "Must be a number",
      missing_city: "Missing City",
      missing_state: "Missing State",
      missing_zip_code: "Missing Zip-Code",
      missing_country: "Missing Country",
      missing_country_id: "Missing Country",
      missing_phone: "Missing Phone",
      missing_email: "Missing Email",
      missing_name: "Missing Name",
      missing_company_name: "Missing Company Name",
      missing_first_address_line: "Missing First Address Line",
      missing_second_address_line: "Missing Second Address Line",
      missing_third_address_line: "Missing Third Address Line",
      missing_address_first_line: "Missing First Address Line",
      missing_address_second_line: "Missing Second Address Line",
      missing_address_third_line: "Missing Third Address Line",
      missing_pickup_point_id: "Missing Pickup Point Id",
      missing_shipper_reference: "Missing Shipper Reference",
      missing_supplier_id: "Missing Supplier",
      missing_product_id: "Missing product",
      missing_supplier: "Missing Supplier",
      missing_product: "Missing product",
      missing_products: "Missing products",
      missing_weight: "Missing Weight",
      missing_length: "Missing Length",
      missing_width: "Missing Width",
      missing_height: "Missing Height",
      missing_value: "Missing Value",
      missing_value_currency: "Missing Value Currency",
      missing_price_value: "Missing Price Value",
      missing_price_currency: "Missing Price Currency",
      missing_description: "Missing Description",
      missing_sku: "Missing SKU",
      missing_hs_code: "Missing HS-Code",
      missing_quantity: "Missing Quantity",
      missing_purchase_web_address: "Missing Purchase Web Address",
      missing_item_composition: "Missing Item Composition",
      missing_cpc_code: "Missing CPC Code",
      missing_warehouse_number: "Missing Warehouse Number",
      missing_receiver_address: "Missing Receiver Information",
      missing_shipper_address: "Missing Shipper Information",
      missing_vat: "Missing VAT",
      missing_eori: "Missing EORI",
    },
    auth: {
      logout_modal: {
        title: "Log out",
        content: "Are you sure to logout?",
      },
      login: {
        title: "Log in",
        labels: {
          username: "Username",
          password: "Password",
          email: "Email",
        },
        buttons: {
          login: "Login",
          forgot_password: "Forgot password",
        },
      },
    },
    parcels: {
      create: {
        title: "Create parcel",
        buttons: {
          add_product: "Add product",
          remove_product: "Remove",
          cancel: "Cancel",
          create: "Create parcel",
        },
      },
      edit: {
        title: "Edit parcel",
      },
      details: {
        hub: "Hub",
        supplier: "Supplier",
        tracking_number: "Tracking Number",
        accepted: "Accepted",
        product: "Product",
        supplier_system_number: "Supplier System Number",
        products: "Products",
        supplier_order_name: "Supplier Order Name",
        weight: "Weight",
        weight_value: "{weight} kg",
        dimensions_value: "L {length}cm W {width}cm H {height}cm",
        value: "Value",
        dimensions: "Dimensions",
        commercial_invoice: "Commercial Invoice",
        receiver: "Receiver",
        shipper: "Shipper",
        pickup_point: "Pickup Point",
        pickup_point_data: "Pickup Point Data",
        pickup_point_id: "Pickup Point ID",
        shipper_reference: "Shipper Reference",
        product_description: "Product Description",
        country: "Country",
        sku: "SKU",
        purchase_web_address: "Purchase Web Address",
        hs_code: "HS-code",
        item_composition: "Item Composition",
        quantity: "Quantity",
        cpc_code: "CPC-code",
        warehouse_number: "Warehouse Number",
      },
      list: {
        filters: {
          country: "Destination Country",
          supplier: "Supplier",
          product: "Product",
          search:
            "Search by order no., tracking no., receiver company name, name, zipcode, shipper reference (min. 3 characters).",
        },
        title: "Parcels",
        actions: {
          action: "Action",
          download_labels: "Download Labels",
          download_manifest: "Download Manifest",
          export_xlsx: "Export Xlsx",
          create_shipment: "Create parcel",
          modal_content: {
            request_processing: "Your request is processing...",
            no_parcels_checked: "No parcels were checked for the action",
            action_error:
              "There was a problem with processing the action, please contact admin.",
            labels_success:
              "Parcels labels have been processed. Please check your mailbox:",
            manifests_success:
              "Manifest has been processed and is being downloaded onto your machine.",
            export_xlsx_success:
              "Excel Export has been processed and is being downloaded onto your machine.",
          },
        },
        item: {
          labels: {
            id: "ID",
            supplier: "Supplier",
            created_date: "Created date",
            status: "Status",
            created_at: "Created at",
            tracking_link: "Tracking",
            fm_tracking_link: "Final Mile Tracking",
            label: "Label",
          },
          buttons: {
            label: "Label",
            tracking: "Carrier tracking",
            fm_tracking: "FM tracking",
          },
          parcel_id: "Parcel {id}",
          address: "Receiver:",
          sender: "Shipper:",
          pickup_point: "Pickup Point:",
          delivery_at: "Delivery at:",
          delivery_time: "Delivery time:",
          weight: "Weight:",
          scanned_weight: "Scanned Weight:",
          currency: "Currency:",
          created: "Created:",
          info_received: "Info received:",
          out_of_delivery: "Out of delivery:",
          delivered: "Delivered:",
          shipper_reference: "Shipper Reference:",
          return_created_at: "Return created: {created_at}",
        },
      },
      labels: {
        pickup_point: "Pickup Point",
        pickup_point_id: "Pickup Point ID",
        shipper_reference: "Shipper Reference",
        shipping: "Shipping",
        supplier: "Supplier",
        select_supplier: "Select Supplier",
        select_product: "Select Product",
        parcel: "Parcel",
        weight: "Weight",
        length: "Length",
        width: "Width",
        product: "Product",
        height: "Height",
        price: "Price",
        products: "Products",
        description: "Description",
        sku: "SKU",
        hs_code: "HS-Code",
        quantity: "Quantity",
        country: "Country",
        country_id: "Country",
        purchase_web_address: "Purchase Web Address",
        item_composition: "Item Composition",
        cpc_code: "CPC-Code",
        warehouse_number: "Warehouse Number",
        receiver: "Receiver",
        shipper: "Shipper",
        name: "Name",
        company: "Company Name",
        first_address_line: "Address Line 1",
        second_address_line: "Address Line 2",
        third_address_line: "Address Line 3",
        city: "City",
        state: "State",
        zip_code: "Zip-code",
        phone: "Phone",
        email: "E-mail",
        currency: "Currency",
        value: "Value",
        price_currency: "Currency",
        price_value: "Price",
        vat: "VAT",
        eori: "EORI",
        details: "Details",
      },
      placeholders: {
        vat: "Country code (2-4 LETS) and VAT No. (5-20 CHARS)",
        eori: "Country code (2 LETS) and EORI No. (12 CHARS)",
      },
      validation_fields: {
        product_id: "Product",
        supplier_id: "Supplier",
        receiver_address: {
          name: "Receiver's Name",
          company_name: "Receiver's Company Name",
          city: "Receiver's City",
          zip_code: "Receiver's Zip Code",
          state: "Receiver's State",
          country_id: "Receiver's Country",
          phone: "Receiver's Phone Number",
          address_first_line: "Receiver's First Address Line",
          address_second_line: "Receiver's Second Address Line",
          vat: "Receiver's VAT",
          eori: "Receiver's EORI",
        },
        shipper_address: {
          name: "Shipper's Name",
          company_name: "Shipper's Company Name",
          city: "Shipper's City",
          zip_code: "Shipper's Zip Code",
          state: "Receiver's State",
          country_id: "Shipper's Country",
          phone: "Shipper's Phone Number",
          address_first_line: "Shipper's First Address Line",
          address_second_line: "Shipper's Second Address Line",
          vat: "Shipper's VAT",
          eori: "Shipper's EORI",
        },
        price_value: "Price",
        price_currency: "Price Currency",
        products: "Products",
        products_array_item: "Product",
        pickup_point_id: "Pickup Point",
        shipper_reference: "Shipper Reference",
        description: "Description",
        sku: "SKU",
        hs_code: "HS-Code",
        quantity: "Quantity",
        purchase_web_address: "Purchase web address",
        item_composition: "Item composition",
        cpc_code: "CPC-Code",
        warehouse_number: "Warehouse number",
        weight: "Weight",
      },
      general_errors: {
        webshipper:
          "Error occurred when processing your request." +
          "<br/>" +
          "Please contact the administrator and use this code below as reference:",
      },
    },
    bulk_parcels: {
      edit: {
        title: "Edit Bulk Parcel",
        description: "Description (optional)",
        buttons: {
          edit: "Save Bulk Parcel",
        },
      },
      create: {
        title: "Create Bulk Parcel",
        description: "Description (optional)",
        csv: "CSV File*",
        upload_csv: "Upload CSV File",
        buttons: {
          create: "Create Bulk Parcel",
        },
      },
      list: {
        title: "Bulk parcels",
        item: {
          id: "ID",
          id_label: "Bulk {id}",
          created: "Created",
          shipment: "No. parcels",
          parcels_with_errors: "Parcels with errors",
          parcels_with_errors_value: "{count} parcels with errors",
          description: "Description",
          status: "Status",
          parcels_count: "{count} parcels",
          buttons: {
            label: "Labels",
            csv_file: "CSV File",
            errors: "Go to parcels with errors list",
            edit: "Edit description",
          },
          details: {
            description: "Description: {description}",
            parcels_with_errors: "Parcels with errors: {count}",
            parcels_created_correctly: "Correctly created parcels: {count}",
          },
        },
        filters: {
          from_date: "From DD/MM/YYYY",
          to_date: "To DD/MM/YYYY",
          search: "Search by bulk ID",
        },
        actions: {
          csv_template: "CSV Template",
          action: "Action",
          create_bulk_order: "Add Bulk Parcel",
        },
      },
      errors: {
        title: "Bulk {id}",
        item: {
          id_label: "Parcel {id}",
          action_button: "Go to the parcel",
          details: {
            description: "Errors:",
          },
        },
      },
    },
    return_parcels: {
      details: {
        weight_value: "{weight} kg",
      },
      create: {
        title: "Create Return Parcel",
        success_title: "Return Parcel has been created",
        success_description: "Return Parcel has been created",
        success_button: "Proceed to the return",
      },
      edit: {
        title: "Edit Return Parcel",
      },
      list: {
        title: "Return Parcels",
        filters: {
          search:
            "Search by return parcel no., order no. or tracking no. (min. 3 characters).",
        },
        actions: {
          create_return: "Create return parcel",
          return_parcel: "Return Parcel",
          check_return: "Check Return Parcel",
          open_incident: "Open Incident",
        },
        item: {
          return_id: "Return Parcel {id}",
          address: "Address:",
          delivery_at: "Delivery at:",
          delivery_time: "Delivery time:",
          weight: "Weight:",
          currency: "Currency:",
          created: "Created:",
          info_received: "Info received:",
          out_of_delivery: "Out of delivery:",
          delivered: "Delivered:",
          shipper_reference: "Shipper Reference:",
          buttons: {
            label: "Label",
            tracking: "Carrier tracking",
          },
          labels: {
            id: "ID",
            supplier: "Supplier",
            created_date: "Created date",
            status: "Status",
            created_at: "Created at",
            tracking_link: "Tracking",
            fm_tracking_link: "Final Mile Tracking",
            label: "Label",
          },
        },
      },
    },
    general: {
      clear: "Clear",
      empty_list: "No results found. Please try another way.",
      empty_list_min_3_characters:
        "No results found. Please try another way. Type min. 3 characters.",
      apply: "Apply",
      cancel: "Cancel",
      search: "Search",
      all: "All",
      created: "Created",
      in_transit: "In Transit",
      returned: "Returned",
      delivered: "Delivered",
      test_mode: "Test mode",
      accepted: "Accepted",
      received: "Received",
      failed: "Failed",
      type_here: "Type here",
      text_placeholder: "Type here",
      kg: "kg",
      cm: "cm",
      float_zero: "0.00",
      select_placeholder: "Choose one",
      ok: "OK",
    },
    nav: {
      parcels: "Parcels",
      bulk_parcels: "Bulk Parcels",
      returns: "Returns",
      reports: "Reports",
      invoices: "Invoices",
      price_lists: "Price Lists",
      customer_support: "Customer support",
      account: "Account",
      log_out: "Log out",
    },
    report: {
      modal: {
        title: "Reports",
        success_message:
          "Report is being prepared. Please wait, it may take a while.",
        already_preparing_error:
          "Report is already being prepared. Please wait, it may take a while. ",
        general_error: "There was unexpected error. Please contact your admin.",
      },
      create_report: "Create Report",
      refresh_report: "Refresh Report",
      reload: "Reload",
      parcels: {
        title: "Parcels",
        delivered: "Delivered",
        delivered_percentage: "Delivered %",
        unsuccessful: "Unsuccessful",
        chart: {
          title: "Parcels created on",
        },
      },
      parcels_per_country: {
        title: "Parcels per country",
        labels: {
          country: "Country",
          parcels_number: "Parcels No.",
          percent: "%",
        },
      },
      select_period: "current week",
      select_period_1: "current month",
      select_period_2: "3 months",
      select_period_3: "1 year",
      title: "My reports",
      suppliers: {
        title: "Suppliers",
      },
      statistics: {
        parcels: "Parcels",
        avg_parcel_weight: "Average Parcel Weight",
        returns: "Returns",
        returns_percentage: "Returns Percentage",
        bulk_parcels: "Bulk Parcels",
        countries_number: "Countries",
      },
      errors: {
        general_error:
          "There was a problem with processing the report, please contact admin.",
        missing_report_error:
          "There is no report created for specified period. Please use the button above to create the report.",
        report_being_prepared_error:
          "Your report is being prepared. Please wait or click the button below.",
      },
    },
  },
};

const numberFormats = {
  en: {
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

const i18n = createI18n({
  locale: "en",
  globalInjection: true,
  messages,
  numberFormats,
});

export default i18n;
